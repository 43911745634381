import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    { attrs: { wrap: "" } },
    [
      _c(
        VNavigationDrawer,
        {
          staticStyle: { width: "300px" },
          attrs: { absolute: "", permanent: "", right: "" },
        },
        [
          _c(
            VRow,
            {
              staticStyle: { "margin-left": "5px" },
              attrs: { align: "center", justify: "start" },
            },
            [
              _vm._v(
                "\n            (" + _vm._s(_vm.lists.length) + ")\n            "
              ),
              _vm._l(_vm.lists, function (list, index) {
                return _vm.lists.length > 0
                  ? _c("div", [
                      _vm._v(
                        "\n                " +
                          _vm._s(list.userName) +
                          ",\n            "
                      ),
                    ])
                  : _vm._e()
              }),
              _c(
                VRow,
                {
                  staticStyle: { "margin-right": "10px" },
                  attrs: { align: "center", justify: "end" },
                },
                [
                  _c(
                    VTooltip,
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                VBtn,
                                {
                                  attrs: { icon: "" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.close()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    VIcon,
                                    { attrs: { color: "grey lighten-1" } },
                                    [_vm._v("mdi-close")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.$t("word.close")) + " "),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._l(_vm.participantLists, function (participant, index) {
            return _c(
              "div",
              [
                _c(
                  VCard,
                  {
                    staticClass: "mx-auto",
                    attrs: { "max-width": "300", outlined: "" },
                  },
                  [
                    _c(
                      VListItem,
                      { attrs: { "three-line": "" } },
                      [
                        _c(
                          VListItemAvatar,
                          { attrs: { size: "36", color: "grey" } },
                          [
                            _c("img", {
                              attrs: {
                                src: participant.picture,
                                alt: participant.userName,
                              },
                            }),
                          ]
                        ),
                        _c(
                          VListItemContent,
                          [
                            _c(VListItemTitle, [
                              _vm._v(_vm._s(participant.userName)),
                            ]),
                            _c(VListItemSubtitle, [
                              _vm._v(_vm._s(participant.email)),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }