import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      attrs: { width: "400" },
      on: {
        "click:outside": function ($event) {
          return _vm.closeDialog()
        },
      },
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _c(
        "div",
        [
          _c("purchase-item-template", {
            attrs: {
              "main-title": _vm.getMainTitle,
              title: _vm.getTitle,
              itemSubTitle: _vm.getSubTitle,
              "item-class-name": _vm.getClassName,
              "item-lab-name": _vm.getLabName,
              itemAmount: _vm.getAmount,
              "item-period": _vm.getPeriod,
              "user-info": _vm.userInfo,
              thumbnailText: _vm.getThumbnailText,
              thumbnailImg: _vm.getThumbnailImg,
            },
            on: { submit: _vm.submit, close: _vm.closeDialog },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }